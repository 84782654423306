.admin{
    background-color: #f1f1f1;
}
.admin-container{
    background-color: #ffffff;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    border: 10px solid #fff;
    text-align: center;
}
.section_padding-custom {
    padding: 120px 0;
}

.ss-flex{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ss-flex button {
    margin: 0px 5px;
}

.ss-margin-tb-5{
    margin: 5px 0px;
}
.ss-margin-tb-15{
    margin: 15px 0px;
}

.ss-width-adjust{
    width: 50%;
}

.ss-flex-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.ss-margin-rl-5{
    margin: 0px 5px;
}

.ss-table-header{
    background-color: #4169e1;
}

.ss-table-header th{
    background: transparent !important;
    color: white !important;
}

.ss-span-link{
    cursor: pointer;
    text-decoration: underline;
}

.ss-td-text{
    font-weight: bolder;
}

.ss-td-title{
    font-style: oblique;
}

@media only screen and (max-width: 600px){
    .ss-width-adjust{
        width: 100%;
    }
}

.invalid-feedback{
color: tomato;
font-size: small;
padding: 0px 5px;
margin-top: -15px;
}

.ss-select-style{
    margin-bottom: 20px;
    border-radius: 2px;
}

.ss-white{
    color: white !important;
}

.ss-image-resize{
    height: 10%;
    width: 50% !important;
}

.ss-underline{
    border-bottom: 2px solid #4169e1;
}